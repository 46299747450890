import React, { useState, createContext } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import Signup from './components/Auth/Signup';
import Login from './components/Auth/Login';
import VideoUpload from './components/Video/VideoUpload';
import TranscodeSettings from './components/Video/TranscodeSettings';
import DownloadLink from './components/Video/DownloadLink';
import Toolbar from './components/UI/Toolbar';
import './config/aws-config';

// Create a context for disabled state
export const AppContext = createContext();

function App() {
    const [disabled, setDisabled] = useState(false);

    return (
        <AppContext.Provider value={{ disabled, setDisabled }}>
            <Router>
                <MainApp />
            </Router>
        </AppContext.Provider>
    );
}

function MainApp() {
    const location = useLocation();
    const isAuthPage =
        location.pathname === '/login' ||
        location.pathname === '/signup' ||
        location.pathname === '/'; // Also check for the root path

    return (
        <>
            {!isAuthPage && <Toolbar disabled={false} />}
            <Switch>
                <Route path="/signup" component={Signup} />
                <Route path="/login" component={Login} />
                <Route path="/upload" component={VideoUpload} />
                <Route path="/transcode" component={TranscodeSettings} />
                <Route path="/download" component={DownloadLink} />
                <Route path="/" component={Login} />
            </Switch>
        </>
    );
}

export default App;