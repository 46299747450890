import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import {startTranscoding, getTranscodingProgress, getUserActiveJobs} from '../../services/videoService';
import ProgressBarPortal from './ProgressBarPortal';
import { AppContext } from '../../App';
import { FiSettings } from 'react-icons/fi';

function TranscodeSettings() {
    const location = useLocation();
    const [uploadId, setUploadId] = useState(null);
    const [format, setFormat] = useState('mp4');
    const [resolution, setResolution] = useState('720p');
    const [bitrate, setBitrate] = useState('5000');
    const [filename, setFilename] = useState('');
    const [progress, setProgress] = useState(0);

    // Context
    const { setDisabled } = useContext(AppContext);

    // Progress Bar
    const [transcoding, setTranscoding] = useState(false);

    // Job system
    const [jobId, setJobId] = useState(null);
    const [status, setStatus] = useState(null);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [queuePosition, setQueuePosition] = useState(null);
    const [estimatedWaitTime, setEstimatedWaitTime] = useState(null);

    // When transcoding state changes, update disabled state
    useEffect(() => {
        setDisabled(transcoding);
    }, [transcoding, setDisabled]);

    // Get uploadId from URL parameters
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const id = params.get('uploadId');
        if (id) {
            setUploadId(id);
        } else {
            console.error('No upload ID provided');
            window.location.href = '/upload';
        }
    }, [location]);

    // Load settings based on uploadId
    useEffect(() => {
        if (uploadId) {
            const storedFormat = localStorage.getItem(`format-${uploadId}`) || 'mp4';
            const storedResolution = localStorage.getItem(`resolution-${uploadId}`) || '720p';
            const storedBitrate = localStorage.getItem(`bitrate-${uploadId}`) || '5000';
            const inputKey = localStorage.getItem(`inputKey-${uploadId}`);

            setFormat(storedFormat);
            setResolution(storedResolution);
            setBitrate(storedBitrate);
            setFilename(inputKey ? inputKey.split('/').pop() : 'example.mp4');

            if (!inputKey) {
                console.error('No input key found for upload ID:', uploadId);
                window.location.href = '/upload';
            }
        }
    }, [uploadId]);

    // Redirection logic when transcoding is complete
    const redirectToDownload = () => {
        const outputKey = localStorage.getItem(`outputKey-${uploadId}`);
        if (outputKey) {
            const encodedKey = encodeURIComponent(outputKey);
            console.log(`Redirecting to download page with key: ${encodedKey}`);
            window.location.href = `/download?key=${encodedKey}`;
        } else {
            console.error('No outputKey found in localStorage for redirection.');
        }
    };

    // Poll progress function (always call if transcoding is happening)
    const pollProgress = useCallback(async () => {
        if (!transcoding || !jobId) return;

        try {
            const token = localStorage.getItem('token');
            const { progress: progressValue, status: jobStatus, queuePosition: position, estimatedWaitTime: waitTime } =
                await getTranscodingProgress(jobId, token);

            console.log(`Progress update - Job: ${jobId}, Progress: ${progressValue}%, Status: ${jobStatus}, Queue Position: ${position}, Wait Time: ${waitTime}s`);

            setProgress(progressValue);
            setStatus(jobStatus);
            setQueuePosition(position);
            setEstimatedWaitTime(waitTime);

            if (jobStatus === 'COMPLETED') {
                setTranscoding(false);
                redirectToDownload();
            } else if (jobStatus === 'ERROR') {
                setTranscoding(false);
                alert('Transcoding failed. Please try again.');
            } else {
                setTimeout(pollProgress, 1000);
            }
        } catch (err) {
            console.error('Error polling progress:', err);
            // Retry after 1 second if there's an error and transcoding isn't complete
            if (transcoding) {
                setTimeout(pollProgress, 1000);
            }
        }
    }, [transcoding, jobId]);

    // On page load, check transcoding status and restore state if needed
    useEffect(() => {
        // Check if transcoding was happening before a refresh
        const checkTranscoding = async () => {
            try {
                // Only check active jobs on initial load
                if (isInitialLoad) {
                    const token = localStorage.getItem('token');
                    const activeJobs = await getUserActiveJobs(token);

                    // If we have an active job for the current file
                    const currentFile = localStorage.getItem(`inputKey-${uploadId}`);
                    const currentJob = activeJobs.find(job => job.inputKey === currentFile);

                    if (currentJob) {
                        console.log(`Found active job for current file: ${currentJob.jobId}`);
                        setJobId(currentJob.jobId);
                        setProgress(currentJob.progress || 0);
                        setTranscoding(true);
                    }
                    setIsInitialLoad(false);
                }
                // If we have a jobId (either from initial load or new transcode)
                else if (jobId) {
                    const token = localStorage.getItem('token');
                    console.log(`UseEffect - Job: ${jobId}`);
                    const { progress: progressValue, status: jobStatus } =
                        await getTranscodingProgress(jobId, token);

                    console.log(`Checking status for job ${jobId}: ${progressValue}%, ${jobStatus}`);

                    if (progressValue > 0 && progressValue < 100) {
                        setTranscoding(true);
                        setProgress(progressValue);
                        pollProgress();
                    } else if (progressValue === 100) {
                        redirectToDownload();
                    }
                }
            } catch (err) {
                console.error('Error checking transcoding status:', err);
            }
        };

        // Only run checkTranscoding if we have an uploadId
        if (uploadId) {
            checkTranscoding();
        }
    }, [jobId, pollProgress, isInitialLoad, uploadId, redirectToDownload]);

    // Poll progress whenever the component is mounted or transcoding starts
    useEffect(() => {
        if (transcoding && jobId) {
            pollProgress();
        }
    }, [transcoding, jobId, pollProgress]);

    // When transcoding starts, save the state and start polling progress
    const handleStartTranscoding = async (e) => {
        e.preventDefault();
        if (!uploadId) {
            alert('No upload ID found. Please upload a video first.');
            window.location.href = '/upload';
            return;
        }

        const token = localStorage.getItem('token');
        const inputKey = localStorage.getItem(`inputKey-${uploadId}`);

        if (!inputKey) {
            alert('No input file found. Please upload a video first.');
            window.location.href = '/upload';
            return;
        }

        const outputKey = inputKey.replace(/\.[^/.]+$/, `.${format}`);
        localStorage.setItem(`outputKey-${uploadId}`, outputKey);

        console.log(`Starting transcoding with inputKey: ${inputKey}`);
        console.log(`Generated outputKey: ${outputKey}`);

        const settings = {
            ContainerSettings: {
                Container: format.toUpperCase(),
            },
            VideoDescription: {
                CodecSettings: {
                    Codec: 'H_264',
                },
                Resolution: resolution,
                Bitrate: bitrate,
            },
        };

        try {
            console.log('Starting transcoding...');

            // Start the new job
            const response = await startTranscoding({ inputKey, outputKey, settings }, token);

            if (response && response.jobId) {
                console.log(`Transcoding job started with ID: ${response.jobId}`);
                // Set the jobId first, then transcoding state
                setJobId(response.jobId);
                setTranscoding(true);
            } else {
                throw new Error('No job ID received from server');
            }
        } catch (err) {
            console.error('Error starting transcoding:', err);
            alert(`Error: ${err.message}`);
            setTranscoding(false);
        }
    };

    const handleBackClick = () => {
        if (uploadId) {
            localStorage.removeItem(`outputKey-${uploadId}`);
            localStorage.removeItem(`transcodingComplete-${uploadId}`);
        }
        window.location.href = '/upload';
    };


    return (
        <div className="transcode-container">
            <div className="heading-with-icon">
                <FiSettings className="icon-left"/>
                <h2>Transcode Video</h2>
            </div>
            <div className="settings-container">
                <label>File:</label>
                <input type="text" value={filename} disabled/>
            </div>
            <div className="settings-container">
                <label>Format:</label>
                <input type="text" value={format} disabled/>
            </div>
            <div className="settings-container">
                <label>Resolution:</label>
                <input type="text" value={resolution} disabled/>
            </div>
            <div className="settings-container">
                <label>Bitrate:</label>
                <input type="text" value={bitrate} disabled/>
            </div>
            <button className="button transcode-button" onClick={handleStartTranscoding} disabled={transcoding}>
                {transcoding ? 'Transcoding...' : 'Transcode'}
            </button>

            {transcoding && (
                <ProgressBarPortal
                    progress={progress}
                    message="Transcoding"
                    queuePosition={queuePosition}
                    status={status}
                    estimatedWaitTime={estimatedWaitTime}
                />
            )}

            <button
                onClick={handleBackClick}
                className={`button back-button ${transcoding ? 'disabled' : ''}`}
                disabled={transcoding}
            >
                Back to Upload
            </button>
        </div>
    );
}

export default TranscodeSettings;
