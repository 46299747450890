import { Amplify } from 'aws-amplify';

Amplify.configure({
    Auth: {
        region: 'ap-southeast-2',
        userPoolId: 'ap-southeast-2_eDjHiUMll',
        userPoolWebClientId: '2en58mijb27keiakvp0m8joro',
        mandatorySignIn: true,
        oauth: {
            domain: 'n11670576.auth.ap-southeast-2.amazoncognito.com',
            scope: ['email', 'openid', 'profile'],
            redirectSignIn: 'https://n11670576.cab432.com/upload',
            redirectSignOut: 'https://n11670576.cab432.com/',
            responseType: 'code',
        }
    },
    Storage: {
        AWSS3: {
            bucket: 'n11670576-upload-bucket',
            region: 'ap-southeast-2',
        }
    }
});