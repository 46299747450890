import axios from 'axios';

const API_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

export const getUploadURL = (filename, token) => {
    console.log('API_URL:', API_URL);
    console.log('Filename:', filename);
    console.log('Token:', token);

    return axios.get(`${API_URL}/api/video/upload-url`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: { filename },
    });
};

export const startTranscoding = async (data, token) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
    };

    try {
        const response = await axios.post(`${API_URL}/api/video/transcode`, data, { headers });
        return response.data;
    } catch (error) {
        console.error('Error starting transcoding:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const getDownloadURL = async (key, token) => {
    try {
        const response = await axios.get(`${API_URL}/api/video/download-url`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: { key },
        });
        return response.data;
    } catch (error) {
        console.error('Error in getDownloadURL:', error);
        throw error;
    }
};

// Fetch the current transcoding progress
export const getTranscodingProgress = async (jobId, token) => {
    if (!jobId) {
        console.error('No job ID provided for progress check');
        return { progress: 0, status: 'UNKNOWN' };
    }

    try {
        // console.log(`Checking progress for job: ${jobId}`);
        const response = await axios.get(`${API_URL}/api/video/job/${jobId}/progress`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );

        console.log('Progress response:', response.data);

        // Explicitly handle the estimatedWaitTime
        const estimatedWaitTime = response.data.estimatedWaitTime !== undefined
            ? response.data.estimatedWaitTime
            : 0;

        return {
            progress: response.data.progress || 0,
            status: response.data.status || 'PROCESSING',
            queuePosition: response.data.queuePosition,
            estimatedWaitTime: estimatedWaitTime
        };
    } catch (error) {
        if (error.response && error.response.status === 404) {
            console.log(`Job ${jobId} not found, might be queued`);
            return {
                progress: 0,
                status: error.response.data?.status || 'QUEUED',
                estimatedWaitTime: 0
            };
        }

        console.error('Error fetching transcoding progress:', error);
        return {
            progress: 0,
            status: 'ERROR',
            estimatedWaitTime: 0
        };
    }
};

// Get all active jobs for a user
export const getUserActiveJobs = async (token) => {
    console.log('getUserActiveJobs. Token:', token);
    try {
        const response = await axios.get(`${API_URL}/api/video/active-jobs`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data.jobs;
    } catch (error) {
        console.error('Error fetching active jobs:', error);
        throw error;
    }
};