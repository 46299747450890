import React, { useEffect, useState, useContext } from 'react';
import { getUploadURL } from '../../services/videoService';
import ProgressBarPortal from './ProgressBarPortal';
import { AppContext } from '../../App';
import { FiUpload } from 'react-icons/fi';
import { Auth } from 'aws-amplify';
import { logVideoUpload } from '../../services/analyticsService';

function VideoUpload() {
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [resolution, setResolution] = useState('720p');
    const [bitrate, setBitrate] = useState('1500');
    const [format, setFormat] = useState('mp4');
    const [maxFileSize, setMaxFileSize] = useState(null);
    const [userGroup, setUserGroup] = useState('');
    const [uploadId, setUploadId] = useState(null);

    // Context
    const { setDisabled } = useContext(AppContext);

    // Generate a unique upload ID when component mounts
    useEffect(() => {
        const newUploadId = `upload-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
        setUploadId(newUploadId);
        // Clear any previous upload data for this tab
        if (sessionStorage.getItem('currentUploadId')) {
            const oldUploadId = sessionStorage.getItem('currentUploadId');
            localStorage.removeItem(`inputKey-${oldUploadId}`);
            localStorage.removeItem(`resolution-${oldUploadId}`);
            localStorage.removeItem(`bitrate-${oldUploadId}`);
            localStorage.removeItem(`format-${oldUploadId}`);
        }
        sessionStorage.setItem('currentUploadId', newUploadId);
    }, []);

    // When uploading state changes, update disabled state
    useEffect(() => {
        setDisabled(uploading);
    }, [uploading, setDisabled]);

    // Fetch the user's group from Cognito and set file size limits
    useEffect(() => {
        const fetchUserGroup = async () => {
            try {
                const user = await Auth.currentAuthenticatedUser();  // Fetch user info
                const groups = user.signInUserSession.accessToken.payload['cognito:groups'] || [];
                console.log('User groups:', groups);

                if (groups.includes('Free')) {
                    setMaxFileSize(10 * 1024 * 1024); // 10 MB for free users
                    setUserGroup('Free');
                } else if (groups.includes('Paid')) {
                    setMaxFileSize(null); // No limit for paid users
                    setUserGroup('Paid');
                } else {
                    console.warn('User is not assigned to a known group.');
                }
            } catch (error) {
                console.error('Error fetching user group:', error);
            }
        };

        fetchUserGroup();
    }, []);

    // Clear previous transcoding data
    useEffect(() => {
        console.log('VideoUpload component mounted');
        localStorage.removeItem('outputKey');
        localStorage.removeItem('transcodingComplete');
        localStorage.removeItem('inputKey');
        console.log('Cleared transcoding data from localStorage');
    }, []);

    const handleUpload = async (e) => {
        e.preventDefault();

        if (!uploadId) {
            console.error('No upload ID available');
            return;
        }

        // Check file size if maxFileSize is set
        if (file && maxFileSize && file.size > maxFileSize) {
            alert('File size exceeds the allowed limit of 10MB for free users.');
            return;
        }

        // Clear previous transcoding data for this upload
        localStorage.removeItem(`outputKey-${uploadId}`);
        localStorage.removeItem(`transcodingComplete-${uploadId}`);
        localStorage.removeItem(`inputKey-${uploadId}`);

        const token = localStorage.getItem('token');
        setUploading(true);

        try {
            const response = await getUploadURL(file.name, token);
            const uploadURL = response.data.url;
            const key = `${localStorage.getItem('userEmail')}/${file.name}`;

            // Create a new XMLHttpRequest to track upload progress
            const xhr = new XMLHttpRequest();
            xhr.open('PUT', uploadURL, true);
            xhr.setRequestHeader('Content-Type', file.type);

            xhr.upload.onprogress = (event) => {
                if (event.lengthComputable) {
                    const percentComplete = Math.round((event.loaded / event.total) * 100);
                    setProgress(percentComplete);
                }
            };

            xhr.onload = async function () {
                if (xhr.status === 200) {
                    // Store upload details with unique ID
                    localStorage.setItem(`inputKey-${uploadId}`, key);
                    localStorage.setItem(`resolution-${uploadId}`, resolution);
                    localStorage.setItem(`bitrate-${uploadId}`, bitrate);
                    localStorage.setItem(`format-${uploadId}`, format);

                    console.log(`Video upload: inputKey=${key}, uploadId=${uploadId}`);

                    // Log the upload in DynamoDB
                    const userEmail = localStorage.getItem('userEmail');
                    if (!token) {
                        console.error('Token is missing before logging upload');
                    }
                    await logVideoUpload(userEmail, file.name, file.size, token);

                    setProgress(100);
                    setUploading(false);
                    window.location.href = `/transcode?uploadId=${uploadId}`;
                } else {
                    alert('Upload failed');
                    setUploading(false);
                }
            };

            xhr.onerror = function () {
                alert('Upload failed');
                setUploading(false);
            };

            xhr.send(file);
        } catch (err) {
            console.error(err);
            alert(err.message);
            setUploading(false);
        }
    };

    return (
        <div className="upload-container">
            <div className="heading-with-icon">
                <FiUpload className="icon-left"/>
                <h2 className="centered-heading">Upload Your Video</h2>
            </div>
            <form onSubmit={handleUpload}>
                {/* Transcoding settings */}
                <div className="settings-container">
                    <label>File:</label>
                    <div className="custom-file-input-container">
                        <div className="custom-file-input">
                            <label className="custom-file-label">
                                <input
                                    type="file"
                                    accept="video/*"
                                    onChange={(e) => setFile(e.target.files[0])}
                                    required
                                    className="upload-select"
                                />
                                {file ? file.name : "No file chosen"}
                            </label>
                        </div>
                    </div>

                    <label>Format:</label>
                    <select value={format} onChange={(e) => setFormat(e.target.value)} className="upload-select">
                        <option value="mp4">MP4</option>
                    </select><br/>

                    <label>Resolution:</label>
                    <select value={resolution} onChange={(e) => setResolution(e.target.value)}
                            className="upload-select">
                        <option value="720p">720p</option>
                        <option value="1080p">1080p</option>
                        <option value="4K">4K</option>
                    </select><br/>

                    <label>Bitrate:</label> {/* Updated label to Bitrate */}
                    <select value={bitrate} onChange={(e) => setBitrate(e.target.value)} className="upload-select">
                        <option value="1500">1500</option>
                        <option value="3000">3000</option>
                        <option value="6000">6000</option>
                    </select><br/>
                </div>

                <button type="submit" className="button upload-button" disabled={uploading}>
                    {uploading ? 'Uploading...' : 'Upload'}
                </button>
            </form>

            {/* Show ProgressBarPortal when uploading */}
            {uploading && <ProgressBarPortal progress={progress} message="Uploading"/>}
        </div>
    );
}

export default VideoUpload;
