import React, { useState, useEffect } from 'react';
import { getDownloadURL } from '../../services/videoService';
import { FiDownload } from 'react-icons/fi';
import { logVideoDownload } from '../../services/analyticsService';

function DownloadLink() {
    const [downloadUrl, setDownloadUrl] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [linkCopied, setLinkCopied] = useState(false);

    const urlParams = new URLSearchParams(window.location.search);
    const outputKey = decodeURIComponent(urlParams.get('key')); // Decode the key to handle any special characters
    const transcodedKey = outputKey.replace(/\.[^/.]+$/, '-transcoded.mp4'); // Append '-transcoded.mp4' to the key
    console.log(`[DownloadLink] Decoded outputKey from URL: ${outputKey}`);
    console.log(`[DownloadLink] Transcoded key: ${transcodedKey}`);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            console.error('[DownloadLink] No token found in localStorage.');
            setError('Authentication token not found.');
            setLoading(false);
            return;
        } else {
            console.log(`[DownloadLink] Retrieved token from localStorage: ${token}`);
        }

        const fetchDownloadUrl = async () => {
            if (transcodedKey) {
                try {
                    setLoading(true);
                    console.log(`[DownloadLink] Fetching download URL for transcodedKey: ${transcodedKey}`);

                    // Fetch the presigned download URL from the server
                    const response = await getDownloadURL(transcodedKey, token);

                    // Log the entire response object in detail
                    console.log('[DownloadLink] Full response from server:', JSON.stringify(response, null, 2));

                    // Ensure response.url is a valid string or object
                    if (typeof response.url === 'string') {
                        console.log(`[DownloadLink] Successfully received download URL: ${response.url}`);
                        setDownloadUrl(response.url);
                    } else if (typeof response.url === 'object') {
                        console.log('[DownloadLink] URL is an object, inspecting fields:', response.url);

                        // Check for a valid URL inside the object
                        const actualUrl = response.url.url || response.url.href || response.url.downloadLink || null;
                        if (actualUrl) {
                            console.log(`[DownloadLink] Extracted download URL from object: ${actualUrl}`);
                            setDownloadUrl(actualUrl);
                        } else {
                            console.error('[DownloadLink] No valid URL found inside the URL object.');
                            throw new Error('No valid URL found inside the URL object.');
                        }
                    } else {
                        console.error('[DownloadLink] No URL field found in the server response.');
                        throw new Error('No URL received from the server.');
                    }

                    setLoading(false);
                } catch (error) {
                    console.error('[DownloadLink] Error fetching download URL:', error);

                    // Detailed error handling with more granularity
                    if (error.response && error.response.status) {
                        if (error.response.status === 401) {
                            console.error('[DownloadLink] Authentication failed (401)');
                            setError('Authentication failed. Please log in again.');
                        } else if (error.response.status === 404) {
                            console.error('[DownloadLink] File not found (404)');
                            setError('The requested file was not found. It may have been deleted or moved.');
                        } else {
                            console.error(`[DownloadLink] Server returned status ${error.response.status}`);
                            setError('Failed to get download URL. Please try again.');
                        }
                    } else {
                        console.error('[DownloadLink] Network error or no response from server.');
                        setError('Network error. Please check your connection.');
                    }

                    setLoading(false);
                }
            } else {
                console.error('[DownloadLink] No output key found in URL.');
                setError('No output key found. Please try transcoding again.');
                setLoading(false);
            }
        };

        fetchDownloadUrl();
    }, [transcodedKey]);

    const handleDownload = async () => {
        try {
            console.log(`[DownloadLink] Attempting to download from URL: ${downloadUrl}`); // Log the correct URL

            // Fetch the file using the presigned URL without Authorization headers
            const response = await fetch(downloadUrl);

            console.log(`[DownloadLink] Response status: ${response.status}`);
            console.log(`[DownloadLink] Response content-type: ${response.headers.get('Content-Type')}`);

            // Check for error responses
            if (!response.ok) {
                const errorText = await response.text(); // Log the body of the error
                console.error(`[DownloadLink] Download failed with status ${response.status}`);
                console.error(`[DownloadLink] Error response body: ${errorText}`); // Log the full error response
                throw new Error('Download failed');
            }

            const blob = await response.blob();
            console.log(`[DownloadLink] Blob size: ${blob.size} bytes`);

            if (blob.size > 0) {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;

                // Log the video download
                const token = localStorage.getItem('token');
                const userEmail = localStorage.getItem('userEmail');
                await logVideoDownload(userEmail, outputKey, blob.size, token);
                console.log('[DownloadLink] Video download logged successfully');

                const filename = outputKey.split('/').pop();
                console.log(`[DownloadLink] Downloading file: ${filename}`);
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                console.log('[DownloadLink] File download completed.');
            } else {
                console.error('[DownloadLink] Downloaded file is empty.');
                setError('Downloaded file is empty. Please try again.');
            }
        } catch (error) {
            console.error('[DownloadLink] Error during file download:', error);
            setError('Failed to download the file. Please try again.');
        }
    };

    const handleBackClick = () => {
        console.log('[DownloadLink] Navigating back to upload page.');
        localStorage.removeItem('outputKey');
        localStorage.removeItem('transcodingComplete');
        window.location.href = '/upload';
    };

    const handleLoginClick = () => {
        console.log('[DownloadLink] Redirecting to login page.');
        window.location.href = '/login';
    };

    // Handle copying the download link to the clipboard
    const copyToClipboard = (text) => {
        return new Promise((resolve, reject) => {
            if (navigator.clipboard && navigator.clipboard.writeText) {
                // Modern browsers
                navigator.clipboard.writeText(text)
                    .then(resolve)
                    .catch(reject);
            } else {
                // Fallback for older browsers
                const textArea = document.createElement("textarea");
                textArea.value = text;
                textArea.style.position = "fixed";  // Avoid scrolling to bottom
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();

                try {
                    const successful = document.execCommand('copy');
                    if (successful) {
                        resolve();
                    } else {
                        reject(new Error('Unable to copy to clipboard'));
                    }
                } catch (err) {
                    reject(err);
                }

                document.body.removeChild(textArea);
            }
        });
    };

    const handleCopyLink = () => {
        copyToClipboard(downloadUrl)
            .then(() => {
                setLinkCopied(true);
                setTimeout(() => setLinkCopied(false), 3000);
                console.log('[DownloadLink] Download link copied to clipboard.');
            })
            .catch((err) => {
                console.error('[DownloadLink] Failed to copy link:', err);
            });
    };

    if (loading) {
        return <div className="download-container"><p>Preparing your download link...</p></div>;
    }

    if (error) {
        console.error(`[DownloadLink] Error occurred: ${error}`);
        return (
            <div className="download-container">
                <p>{error}</p>
                {error.includes('Authentication failed') ? (
                    <button onClick={handleLoginClick}>Go to Login</button>
                ) : (
                    <button onClick={handleBackClick}>Back to Upload</button>
                )}
            </div>
        );
    }

    return (
        <div className="download-container">
            <div className="heading-with-icon">
                <FiDownload className="icon-left"/>
                <h2>Your Video is Ready!</h2>
            </div>
            {downloadUrl ? (
                <>
                    <button onClick={handleDownload} className="button download-button">
                        Download Your Video
                    </button>
                    <br/>
                    <button onClick={handleCopyLink} className="button download-button">
                        Get Download Link
                    </button>
                    <br/>
                    <button onClick={handleBackClick} className="button back-button">
                        Back to Upload
                    </button>
                </>
            ) : (
                <p>Something went wrong. Please try again.</p>
            )}
        </div>
    );
}

export default DownloadLink;
