import React, { useState, useEffect, useRef } from 'react';
import { FiUpload, FiLogOut } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import './Toolbar.css';

function Toolbar({ disabled }) {
    const history = useHistory();
    const [showMenu, setShowMenu] = useState(false);
    const modalRef = useRef();

    const handleUploadClick = () => {
        if (!disabled) {
            history.push('/upload');
        }
    };

    const handleLogoutClick = () => {
        if (!disabled) {
            setShowMenu(true);
        }
    };

    const handleConfirmLogout = () => {
        localStorage.clear();
        setShowMenu(false); // Close the menu after action
        history.push('/login');
    };

    const handleCancelLogout = () => {
        setShowMenu(false);
    };

    // Close modal if clicked outside
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setShowMenu(false);
            }
        };

        if (showMenu) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [showMenu]);

    return (
        <>
            <div className="toolbar">
                <div className="toolbar-icons">
                    <FiLogOut
                        className={`toolbar-icon ${disabled ? 'disabled' : ''}`}
                        onClick={handleLogoutClick}
                    />
                    <FiUpload
                        className={`toolbar-icon ${disabled ? 'disabled' : ''}`}
                        onClick={handleUploadClick}
                    />
                </div>
            </div>

            {/* Portal Popup for Logout Confirmation */}
            {showMenu && (
                <div className="modal-overlay">
                    <div className="modal" ref={modalRef}>
                        <h2>Confirm Logout</h2>
                        <p>Are you sure you want to sign out?</p>
                        <button onClick={handleConfirmLogout} className="modal-button confirm">
                            Yes
                        </button>
                        <button onClick={handleCancelLogout} className="modal-button cancel">
                            No
                        </button>
                    </div>
                </div>
            )}
        </>
    );
}

export default Toolbar;
