import React from 'react';
import ReactDOM from 'react-dom';
import ProgressBar from './ProgressBar';
import './ProgressBarPortal.css';

function formatTime(seconds) {
    if (seconds === 0) {
        return "Calculating...";
    }

    if (seconds < 60) {
        return `${Math.round(seconds)} seconds`;
    } else if (seconds < 3600) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.round(seconds % 60);
        return `${minutes} minute${minutes !== 1 ? 's' : ''}${remainingSeconds > 0 ? ` ${remainingSeconds} seconds` : ''}`;
    } else {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        return `${hours} hour${hours !== 1 ? 's' : ''}${minutes > 0 ? ` ${minutes} minutes` : ''}`;
    }
}

function ProgressBarPortal({ progress, message, queuePosition, status, estimatedWaitTime }) {
    console.log('ProgressBarPortal props:', { progress, message, queuePosition, status, estimatedWaitTime });

    const content = status === 'QUEUED' && queuePosition ? (
        <div className="progress-modal">
            <h2>In Queue</h2>
            <p className="queue-text">Position: {queuePosition}</p>
            <p className="queue-text wait-time">
                Estimated wait: {formatTime(estimatedWaitTime || 0)}
            </p>
            <div className="queue-indicator"></div>
        </div>
    ) : (
        <div className="progress-modal">
            <h2>{message}</h2>
            <ProgressBar progress={progress} />
        </div>
    );

    return ReactDOM.createPortal(
        <div className="progress-overlay">
            {content}
        </div>,
        document.body
    );
}

export default ProgressBarPortal;