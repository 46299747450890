import axios from 'axios';

const API_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

export const logVideoUpload = async (userEmail, videoName, fileSize, token) => {
    console.log('logVideoUpload called with token:', token);
    try {
        const response = await axios.post(`${API_URL}/api/analytics/log-upload`, {
            userEmail,
            videoName,
            fileSize,
            timestamp: new Date().toISOString(),
            actionType: 'Upload',
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        console.log(response.data.message);
    } catch (error) {
        console.error('Error logging video upload:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const logVideoDownload = async (userEmail, videoName, fileSize, token) => {
    try {
        const response = await axios.post(`${API_URL}/api/analytics/log-download`, {
            userEmail,
            videoName,
            fileSize,
            timestamp: new Date().toISOString(),
            actionType: 'Download',
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        console.log(response.data.message);
    } catch (error) {
        console.error('Error logging video download:', error.response ? error.response.data : error.message);
        throw error;
    }
};
