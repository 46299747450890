import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import MfaPortal from './MfaPortal';
import QrCodePortal from './QrCodePortal';
import { Link } from 'react-router-dom';
import './SocialLogin.css';

const parseJwt = (token) => {
    try {
        // Split the token to access the payload (2nd part)
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    } catch (error) {
        console.error('Error decoding token:', error);
        return null;
    }
};

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [mfaCode, setMfaCode] = useState('');
    const [showMfaPortal, setShowMfaPortal] = useState(false);
    const [showQrCodePortal, setShowQrCodePortal] = useState(false);
    const [qrCode, setQrCode] = useState('');
    const [user, setUser] = useState(null);
    const [isMfaSetup, setIsMfaSetup] = useState(false);

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const user = await Auth.signIn(email, password);
            setUser(user);

            // if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
            //     setShowMfaPortal(true); // Show the MFA portal for existing MFA users
            // } else if (user.challengeName === 'MFA_SETUP') {
            //     await handleTOTPSetup(user); // Set up TOTP MFA for new users
            // } else {
            //     await finalizeLogin(user);
            // }
            await finalizeLogin(user);
        } catch (err) {
            console.error('Login error:', err);
            alert(err.message || 'Error logging in');
        }
    };

    const handleTOTPSetup = async (user) => {
        try {
            console.log("Setting up TOTP...");
            const totpCode = await Auth.setupTOTP(user);
            const qrCodeUrl = `otpauth://totp/${encodeURIComponent(user.username)}?secret=${totpCode}&issuer=MyApp`;
            console.log("QR Code URL:", qrCodeUrl);

            setQrCode(qrCodeUrl);
            setIsMfaSetup(true);
            setShowQrCodePortal(true);
        } catch (err) {
            if (err.code === 'NotAuthorizedException') {
                console.error("Session expired or invalid, redirecting to login...");
                alert("Your session has expired. Please log in again.");
                window.location.href = '/login';
            } else {
                console.error('Error setting up TOTP:', err);
                alert('Error setting up MFA. Please try again.');
            }
        }
    };

    const handleMfaSubmit = async (e) => {
        e.preventDefault();
        try {
            if (isMfaSetup) {
                console.log("Verifying TOTP...");
                await Auth.verifyTotpToken(user, mfaCode);
                await Auth.setPreferredMFA(user, 'TOTP');
                console.log("TOTP verified and set as preferred MFA.");
            }

            console.log("Confirming MFA sign-in...");
            await Auth.confirmSignIn(user, mfaCode, 'SOFTWARE_TOKEN_MFA');
            await finalizeLogin(user);
        } catch (err) {
            if (err.code === 'NotAuthorizedException') {
                console.error("Session expired or invalid, redirecting to login...");
                alert("Your session has expired. Please log in again.");
                window.location.href = '/login';
            } else {
                console.error('MFA error:', err);
                alert('Error verifying MFA code: ' + err.message);
            }
        }
    };

    const finalizeLogin = async (user) => {
        // Get tokens after login
        const session = await Auth.currentSession();
        const accessToken = session.getAccessToken().getJwtToken();
        localStorage.setItem('token', accessToken);

        // Manually decode the token
        const decodedToken = parseJwt(accessToken);
        const userEmail = decodedToken.email || decodedToken.username || decodedToken.sub;
        localStorage.setItem('userEmail', userEmail);  // Store the user's email

        window.location.href = '/upload';
    };

    const handleQrCodeNext = () => {
        setShowQrCodePortal(false);
        setShowMfaPortal(true);
    };

    // Handle Google Sign-In using Cognito Federated Identities
    const handleGoogleLogin = async () => {
        try {
            await Auth.federatedSignIn({ provider: 'Google' });
        } catch (err) {
            console.error('Google login error:', err);
            alert('Error logging in with Google');
        }
    };

    return (
        <div className="auth-container">
            <form onSubmit={handleLogin}>
                <h2>Log In</h2>
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                /><br/>
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                /><br/>
                <button type="submit">Log In</button>
            </form>

            <p>Don't have an account? <Link to="/signup">Sign Up</Link></p>

            {/* Divider for Sign in with Google */}
            <hr />
            <div className="social-login-container">
                <h3>Sign in with</h3>
                <button className="social-login-button google-button" onClick={handleGoogleLogin}>
                    Google Account
                </button>
            </div>
        </div>
    );
}

export default Login;