import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';

function Signup() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSignUp = async (e) => {
        e.preventDefault();
        try {
            // Step 1: Sign Up the User
            await Auth.signUp({
                username: email,
                password,
                attributes: { email },
            });

            alert('Sign-Up Successful. Please wait for admin confirmation.');
            window.location.href = '/login';
        } catch (err) {
            console.error('Error during sign up:', err);
            alert(err.message || 'Error signing up');
        }
    };

    return (
        <div className="auth-container">
            <form onSubmit={handleSignUp}>
                <h2>Sign Up</h2>
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                /><br />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                /><br />
                <button type="submit">Sign Up</button>
            </form>

            <p>Already have an account? <Link to="/login">Log In</Link></p>
        </div>
    );
}

export default Signup;
