import React from 'react';

function ProgressBar({ progress }) {
    // Ensure progress is between 0 and 100
    const boundedProgress = Math.min(100, Math.max(0, progress));

    return (
        <div className="progress-bar-container">
            <div className="progress-bar">
                <div
                    className="progress-bar-fill"
                    style={{ width: `${boundedProgress}%` }}
                >
                    <span className="progress-text">{Math.round(boundedProgress)}%</span>
                </div>
            </div>
        </div>
    );
}

export default ProgressBar;